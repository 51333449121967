<!--页脚部分-->
<template>
  <footer id="footer">
    <ul>
      <li><a href="javascript:;">制作人：李长昊</a></li>
      <li><a href="javascript:;">QQ: 1789412739@qq.com</a></li>
      <li><a href="javascript:;" target="_blank">修改时间：2024年8月25日</a></li>
      <li>@Count: 0</li>
    </ul>
  </footer>
</template>

<script>
export default {
  name: "myFooter"
}
</script>

<style scoped>
#footer a {
  color: #919698;
  font-size: 14px;
}
#footer {
  background-color: #eee;
}
#footer ul {
  margin-top: 40px;
  border-top: 1px solid #d5d5d5;
  display: flex;
  justify-content: center;
  height: 80px;
  line-height: 80px;
}
#footer ul li {
  color: #919698;
  font-size: 14px;
  margin-right: 20px;
}
</style>
